import {Divider, Fab, List} from "@mui/material";
import {Add} from "@mui/icons-material";
import {ReactElement, useCallback, useEffect, useState} from "react";
import {Idea, User, w2d2dApiClient} from "../../api-client";
import IdeaPageEntry from "./IdeaPageEntry";
import {useNavigate} from "react-router-dom";
import W2d2dRoutes from "../../W2d2dRoutes";

const fabStyle = {
    position: 'absolute',
    bottom: 72,
    right: 16,
}

export default function IdeasPage({user}: { user?: User }) {

    const navigate = useNavigate()
    const [ideas, setIdeas] = useState<null | Idea[]>(null)

    useEffect(() => {
        let active = true
        w2d2dApiClient.getIdeas()
            .then((ideasList) => {
                if (active) {
                    setIdeas(ideasList.ideas)
                }
            })
        return () => {
            active = false
        }
    }, [])

    const updateIdea = useCallback((idea: Idea) => {
        if (!ideas) {
            return
        }
        let modified = false
        let updatedIdeas = ideas.map((i) => {
            if (i.id === idea.id) {
                modified = true
                return idea
            }
            return i
        })
        if (modified) {
            setIdeas(updatedIdeas)
        }
    }, [setIdeas, ideas])

    const handleClickAdd = useCallback(async () => {
        navigate(W2d2dRoutes.ideas_add)
    }, [])

    let ideasEl: ReactElement[] = []
    if (user && ideas) {
        // TODO: use memo?
        ideas.forEach((idea) => {
            ideasEl.push(<IdeaPageEntry user={user} idea={idea} key={idea.id} updateIdea={updateIdea}/>)
            ideasEl.push(<Divider key={`${idea.id}-divider`}/>)
        })
    }

    let fabEl
    if (ideas) {
        fabEl = (
            <Fab color="primary" aria-label="Neue Idee" sx={fabStyle} onClick={handleClickAdd}>
                <Add/>
            </Fab>
        )
    }

    return (
        <>
            <List sx={{width: '100%'}}>
                {ideasEl}
            </List>
            {fabEl}
        </>
    )
}
