import {useCallback, useEffect, useState} from "react"

let initialized = false


// TODO
// async function createSubscription() {
//     const registration = await navigator.serviceWorker.register('/service-worker.js');
//
//     const subscription = await registration.pushManager.subscribe({
//         userVisibleOnly: true,
//         applicationServerKey: vapidPublicKey,
//     });
//
//     await fetch('/api/save-subscription', {
//         method: 'POST',
//         body: JSON.stringify(subscription),
//         headers: {
//             'Content-Type': 'application/json',
//         },
//     });
// }


export default function useNotifications() {

    const [permissionGranted, setPermissionGranted] = useState(Notification.permission === 'granted')

    const requestPermission = useCallback(async () => {

        if (Notification.permission === 'granted') {
            // already granted
            setPermissionGranted(true)
            return true
        }

        const permissionResult = await Notification.requestPermission()
        setPermissionGranted(permissionResult === 'granted')

    }, [setPermissionGranted])

    useEffect(() => {

        if (initialized) {
            return
        } else {
            initialized = true
        }

        console.log("[pusher] initializePush()")

        async function initializePushNotifications() {
            try {
                // await askPermission()
                // await createSubscription();
            } catch (error) {
                console.error('[pusher] error on initializing push', error);
            }
        }

        initializePushNotifications()
            .then(() => {
                if ('serviceWorker' in navigator) {
                    navigator.serviceWorker.addEventListener('message', event => {
                        const {title, body} = event.data
                        console.log(`[pusher] got message: `, event.data)
                        alert(`Nachricht erhalten: ${title} - ${body}`)
                    })
                }
            })
    }, [setPermissionGranted])

    return {
        permissionGranted,
        requestPermission,
    }
}
