import {Button, Divider, FormControl, List, ListItem, ListItemIcon, ListItemText, Switch} from "@mui/material";
import {ChangeEvent, useCallback} from "react";
import Session from "../../auth/Session";
import {Logout, Notifications} from "@mui/icons-material";
import useNotifications from "../../appfw/notifications/useNotifications";

interface SettingsPageProps {
    readonly session: Session;
}

export default function SettingsPage(props: SettingsPageProps) {

    const notificationsHook = useNotifications()

    const handleNotificationsSwitchChange = useCallback(async () => {
        if (notificationsHook.permissionGranted) {
            // already granted
            // TODO: disable on server
        } else {
            await notificationsHook.requestPermission()
            // TODO: subscribe and store on server
        }
    }, [notificationsHook])

    const handleLogoutClick = useCallback(() => {
        props.session.logout()
    }, [props.session])

    return (
        <>
            <List sx={{width: '100%'}}>
                <ListItem>
                    <ListItemIcon>
                        <Notifications />
                    </ListItemIcon>
                    <ListItemText
                        primary="Benachrichtigungen"
                        secondary="Erlaube uns, Dich über wichtige Ereignisse zu informieren.">
                    </ListItemText>
                    <Switch
                        checked={notificationsHook.permissionGranted}
                        onChange={handleNotificationsSwitchChange}
                    >
                    </Switch>
                </ListItem>
                <Divider/>
                <ListItem onClick={handleLogoutClick} sx={{ cursor: "pointer" }}>
                    <ListItemIcon>
                        <Logout />
                    </ListItemIcon>
                    <ListItemText primary="Abmelden" />
                </ListItem>
            </List>
        </>
    )
}
